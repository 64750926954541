import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/ru/chapter_4/sub_13/slide1';

import WrapperMobile from 'src/slides/mobile/ru/chapter_4/sub_13/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from '../../../components/_shared/seo';

// desktop

const allSlides = [
  <Slide1 />,
];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Что случилось после победы I Варшавская битва " description="Оборона Львова, битва под Задворьем, сражение на Немане - победа под Варшавой не закончила войну." lang="ru" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

// mobile


const Mobile = () => (
  <>
    <SEO title="Что случилось после победы I Варшавская битва " description="Оборона Львова, битва под Задворьем, сражение на Немане - победа под Варшавой не закончила войну." lang="ru" />
    <WrapperMobile />
  </>
);

// export

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};
export default Page;
